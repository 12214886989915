import React from "react";

export const MobileSelector = ({ name, selectedOption, setSelectedOption, options }) => {
  return (
    <select
      value={selectedOption}
      onChange={(e) => setSelectedOption(e.target.value)}
      className="border-[#697276] border-opacity-[16%] bg-white px-4 even:border-l-0"
    >
      <option value="">{name}</option>
      {options.map((option) => (
        <option key={option.name} value={option.value}>
          {option.name}
        </option>
      ))}
    </select>
  );
};

export const DesktopSelector = ({ name, icon, selectedOption, setSelectedOption, options }) => {
  return (
    <div className="flex">
      <div className="ml-6 flex items-center gap-2 font-bold text-[#00AAA6]">
        {icon}
        {name}
      </div>
      {options.map((option) => (
        <button
          key={option.name}
          type="button"
          onClick={() => setSelectedOption(option.value)}
          className={`px-6 font-medium ${selectedOption === option.value && "text-[#00AAA6]"} `}
        >
          {option.name}
        </button>
      ))}
    </div>
  );
};
