import React, { useState } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { Layout } from "@/layout/Layout";
import Seo from "../components/common/Seo";
import ProductCard from "../components/common/ProductCard";
import { MobileSelector, DesktopSelector } from "../components/common/Selectors";

import RefrigeratorIcon from "@/assets/icons/refrigerator.inline.svg";

const WHITE = "white";

const RefrigeradorasPage = ({ pageContext, location }) => {
  const { slug, banner_text, banner_text_color, banner_image, banner_mobile_image, products } = pageContext.category;

  const subcategories = Array.from(new Set(products.map((p) => p.subcategory.name))).map((option) => ({
    name: option,
    value: option,
  }));

  const defatulSubcategory = subcategories.some((subcategory) => subcategory.value === location?.state?.subcategory)
    ? location?.state?.subcategory
    : "";

  const [selectedSubcategory, setSelectedSubcategory] = useState(defatulSubcategory);
  const filteredProducts = selectedSubcategory
    ? products.filter((i) => i.subcategory.name === selectedSubcategory)
    : products;

  return (
    <Layout pageId="refrigeradoras" isHeaderTransparent={true} headerWhiteText={banner_text_color === WHITE}>
      <section className="relative">
        <GatsbyImage
          image={getImage(banner_mobile_image.imageFile)}
          alt={banner_mobile_image.title}
          className="h-auto w-full md:!hidden"
        />
        <GatsbyImage
          image={getImage(banner_image.imageFile)}
          alt={banner_image.title}
          className="!hidden h-auto w-full object-cover md:!block"
        />
        {banner_text && (
          <div
            className={`
              absolute left-0 top-1/2 z-10 w-full -translate-y-1/2 text-center [&>*]:mx-auto
              [&>h1]:text-[32px] [&>h1]:font-bold [&>h1]:leading-10 [&>h1]:md:text-6xl
              [&>p]:md:font-bold [&>p]:text-sm [&>p]:md:text-xl [&>p]:mt-3 [&>p]:md:mt-6 [&>p]:max-w-[50%]
              ${banner_text_color === WHITE ? "[&>*]:text-white" : "[&>*]:text-[#303030]"}
            `}
            dangerouslySetInnerHTML={{ __html: banner_text }}
          />
        )}
      </section>

      {/* Mobile Filters */}
      <div className="grid h-12 font-medium lg:hidden">
        <MobileSelector
          name={"Tipo"}
          selectedOption={selectedSubcategory}
          setSelectedOption={setSelectedSubcategory}
          options={subcategories}
        />
      </div>

      {/* Desktop Filters */}
      <div className="hidden py-12 lg:block">
        <div className="container flex justify-center divide-x divide-[#ECEFF1] text-[#697276]">
          <DesktopSelector
            name={"Tipo:"}
            icon={<RefrigeratorIcon />}
            selectedOption={selectedSubcategory}
            setSelectedOption={setSelectedSubcategory}
            options={subcategories}
          />

          <div>
            <button type="button" onClick={() => setSelectedSubcategory("")} className="ml-6 text-[#00AAA6]">
              Limpiar
            </button>
          </div>
        </div>
      </div>

      {/* Content */}
      <div className="pb-14 md:pb-20">
        <div className="container">
          <div className="row">
            {filteredProducts.map((product) => (
              <div className="md:col-6 lg:col-4" key={product.id}>
                <ProductCard name={product.name} image={product.sliders[0]} link={`/${slug}/${product.slug}`} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const Head = ({ pageContext }) => <Seo title={`${pageContext.category.name} | Hisense`} />;

export default RefrigeradorasPage;
